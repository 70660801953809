<template lang="pug">
  .container
    .wallet-form-box.card
      .card-body
        .row
          .col-sm-12.col-md-6
            h1 Set PIN
            h5 You need to set PIN before continuing.
            form(@submit.prevent='savePin')
              .form-row
                .form-group.col-sm-12
                  label Enter New PIN
                  input.form-control(type='password' autofocus placeholder='Enter 6 digit PIN code' pattern='[0-9]+'  maxlength='6' minlength='6' v-model='pin' required)
                .form-group.col-sm-12
                  label Verify New PIN
                  input.form-control(type='password' placeholder='Enter 6 digit PIN code' maxlength='6' minlength='6' v-model='pinVerify' required)
                .form-group.col-sm-12
                  .show-message(v-if='message') {{message}}
                .form-group.col-sm-12
                  .alert
                .form-group.col-sm-12
                  button.btn.btn-lg.btn-primary(type='submit')
                    .spinner-border.spinner-border-sm(role='status' v-if='loading' aria-hidden="true")
                    .span(v-else) Confirm
                  button.btn.btn-lg.btn-secondary.ml-3(@click='reset' type='button') Reset
</template>

<script>
import api from "@/helpers/api";
import axios from "@/axios";

export default {
  name: "set-pin",
  data() {
    return {
      pin: "",
      pinVerify: "",
      message: "",
      loading: false,
    };
  },
  async mounted() {
    if (!(await api.pinNotCreated())) {
      this.$router.push("/");
    }
  },
  methods: {
    reset() {
      this.pin = "";
      this.pinVerify = "";
    },
    savePin() {
      this.loading = true;
      if (this.pin === this.pinVerify) {
        axios
          .post("api/v1/walletpin/create", {
            pin: this.pin,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.code === 1) {
              this.$router.push("/");
            }
          });
      } else {
        this.loading = false;
        this.message = "PIN do not match";
      }
    },
  },
};
</script>
